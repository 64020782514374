// 计算table列表宽度
function getScrollWidth(colums) {
  // 根据table的列数显示不同的最小宽度
  let scrollWidth = 1050
  if (colums.length < 15) {
    scrollWidth = scrollWidth + (colums.length - 7 > 0 ? colums.length - 7 : 0) * 130
  } else if (colums.length >= 15 && colums.length < 19) {
    scrollWidth = scrollWidth + (colums.length - 7 > 0 ? colums.length - 7 : 0) * 160
  } else if (colums.length >= 19) {
    scrollWidth = scrollWidth + (colums.length - 10 > 0 ? colums.length - 10 : 0) * 120
  }
  return scrollWidth
}

// 获取url参数
function getUrlParam(key) {
    let reg = new RegExp(key + '=([^&]*)');
    let results = window.location.href.match(reg);
    return results ? results[1] : '';
}

const UNIT_TEST_QUESTION_TYPE = [
    {
        type: 'SINGLE_CHOICE',
        name: '单选题'
    },
    {
        type: 'SINGLE_CHOICE_MORE_EMPTY',
        name: '多空单选'
    },
    {
        type: 'MULTIPLE_CHOICE',
        name: '多选择题'
    },
    {
        type: 'CLOZE_TEST',
        name: '完形填空'
    },
    {
        type: 'LINE',
        name: '连线'
    },
    {
        type: 'SORT',
        name: '排序'
    },
    {
        type: 'JUDGE',
        name: '判断题'
    },
]

const CHINESE_NUM = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']

const GRADE_LIST = [
    {
        name: '一年级',
        id: 1
    },
    {
        name: '二年级',
        id: 2
    },
    {
        name: '三年级',
        id: 3
    },{
        name: '四年级',
        id: 4
    },{
        name: '五年级',
        id: 5
    },{
        name: '六年级',
        id: 6
    }
]

// 试卷类型： 私人试卷：privatePaper 共享试卷： sharePaper
const PAPER_TYPE = [
    {
        type: 'privatePaper',
        name: '私人试卷'
    },
    {
        type: 'sharePaper',
        name: '共享试卷'
    },
]

// 题库类型： 私人题库：PRIVATE_PERSON 全年级可见： GRADE
const VISIBLE_RANGE = [
    {
        type: 'PRIVATE_PERSON',
        btn: 'privateBtn',
        name: '仅自己可见'
    },
    {
        type: 'GRADE',
        btn: 'shareBtn',
        name: '全年级可见'
    },
]


// 题目类型
const QUESTION_TYPE = [
    {
        type: 'SINGLE_CHOICE',
        name: '选择题'
    },
    {
        type: 'SINGLE_CHOICE_MORE_EMPTY',
        name: '多空单选',
        hidden: true
    },
    {
        type: 'JUDGE',
        name: '判断题',
        hidden: true
    },
    {
        type: 'MULTIPLE_CHOICE',
        name: '多选择题',
        hidden: true
    },
    {
        type: 'CLOZE_TEST',
        name: '完形填空',
        hidden: true
    },
    {
        type: 'LINE',
        name: '连线题'
    },
    {
        type: 'SORT',
        name: '排序题'
    }
]

export {getScrollWidth, getUrlParam, UNIT_TEST_QUESTION_TYPE, CHINESE_NUM, GRADE_LIST, PAPER_TYPE, VISIBLE_RANGE, QUESTION_TYPE}
