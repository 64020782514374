import { handleResultCode } from '../../service/service'
import taskManageService from '../../service/services/taskService'
const START_GET_LIST = 'START_GET_LIST'
const FINISH_GET_LIST = 'FINISH_GET_LIST'
const START_EDIT_TASK = 'START_EDIT_TASK'
const FINISH_EDIT_TASK = 'FINISH_EDIT_TASK'

const START_GET_TASK_DETAIL = 'START_GET_TASK_DETAIL'
const FINISH_GET_TASK_DETAIL = 'FINISH_GET_TASK_DETAIL'

const initState = {
    taskListIsFetching: false,
    taskList: [],
    pageSize: 10,
    pageIndex: 0,
    totalItemCount: 0,
    edittingTaskIsSubmitting: false,
    getTaskDetailIsFetching: false,
    taskInstanceInfo: null
}


function startGettingTaskList() {
    return {
        type: START_GET_LIST
    }
}

function finishGettingTaskList(pageInfo) {
    return {
        type: FINISH_GET_LIST,
        pageInfo
    }
}

export function getTaskList(params) {
    return (dispatch) => {
        dispatch(startGettingTaskList())
        taskManageService.getTaskList(params)
            .then((response)=>{
                return handleResultCode(response, ()=>{
                    dispatch(finishGettingTaskList({pageInfo: {list: []}}))
                })
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingTaskList(res.data.pageInfo))
                }
            })
    }
}

function startCreateTask() {
    return {
        type: START_EDIT_TASK
    }
}

function finishCreateTask() {
    return {
        type: FINISH_EDIT_TASK
    }
}

export function createTask(params, cb) {
    return (dispatch) => {
        dispatch(startCreateTask())
        taskManageService.createTask(params)
            .then((response)=>{
                return handleResultCode(response, ()=>{
                    dispatch(finishCreateTask())
                })
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishCreateTask())
                    if(cb) {
                        cb()
                    }
                }
            })
    }
}

function startGetTaskDetail() {
    return {
        type: START_GET_TASK_DETAIL
    }
}

function finishGetTaskDetail(taskInstanceInfo) {
    return {
        type: FINISH_GET_TASK_DETAIL,
        taskInstanceInfo
    }
}

export function getTaskDetail(params) {
    return (dispatch) => {
        dispatch(startGetTaskDetail())
        taskManageService.getTaskDetail(params)
            .then((response)=>{
                return handleResultCode(response, ()=>{
                    dispatch(finishGetTaskDetail())
                })
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGetTaskDetail(res.data.taskInstanceInfo))
                }
            })
    }
}

export default function taskManageReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_LIST:
            return {
                ...state,
                taskListIsFetching: true
            }
        case FINISH_GET_LIST:
            return {
                ...state,
                taskListIsFetching: false,
                taskList: action.pageInfo.list,
                pageSize: action.pageInfo.pageSize,
                pageIndex: action.pageInfo.pageIndex,
                currentPage: action.pageInfo.currentPage,
                totalItemCount: action.pageInfo.totalSize
            }
        case START_EDIT_TASK:
            return {
                ...state,
                edittingTaskIsSubmitting: true
            }
        case FINISH_EDIT_TASK:
            return {
                ...state,
                edittingTaskIsSubmitting: false
            }
        case START_GET_TASK_DETAIL:
            return {
                ...state,
                getTaskDetailIsFetching: true,
                taskInstanceInfo: null
            }
        case FINISH_GET_TASK_DETAIL:
            return {
                ...state,
                getTaskDetailIsFetching: false,
                taskInstanceInfo: action.taskInstanceInfo
            }
        default:
            return state
    }
}
