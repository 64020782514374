import { handleResultCode } from '../../service/service'
import questionManageService from '../../service/services/questionManage'
import {Modal} from 'antd'
import {push, goBack} from "react-router-redux"
import {VISIBLE_RANGE} from "../../util";
import {getPrivateBtnQuestions, getShareBtnQuestions} from "../../pages/paperManage/storageManage";
// import {push} from 'react-router-redux'


const START_DELETE_QUESTION = 'START_DELETE_QUESTION'
const FINISH_DELETE_QUESTION = 'FINISH_DELETE_QUESTION'
const FAIL_DELETE_QUESTION = 'FAIL_DELETE_QUESTION'


const START_QUERY_QUESTION = 'START_QUERY_QUESTION'
const FINISH_QUERY_QUESTION = 'FINISH_QUERY_QUESTION'

const START_GET_READ_BOOK = 'START_GET_READ_BOOK'
const FINISH_GET_READ_BOOK = 'FINISH_GET_READ_BOOK'

const START_GROUP_BY_QUESTION_PROPERTY = 'START_GROUP_BY_QUESTION_PROPERTY'
const FINISH_GROUP_BY_QUESTION_PROPERTY = 'FINISH_GROUP_BY_QUESTION_PROPERTY'


const START_CREATE_QUESTION = 'START_CREATE_QUESTION'
const FINISH_CREATE_QUESTION = 'FINISH_CREATE_QUESTION'
const FAIL_CREATE_QUESTION = 'FAIL_CREATE_QUESTION'
const START_LOADING_QUESTION_LIST = 'START_LOADING_QUESTION_LIST'
const FINISH_LOADING_QUESTION_LIST = 'FINISH_LOADING_QUESTION_LIST'

const initState = {
    userProfileIsFetching: false,
    isLoading: false,
    isGegister: false,
    isSwitchRole: false,
    isGettingProfile: false,
    isResetPassword: false,
    personalInfo: null,
    createQuestionList: [],
    captchaCountDown: 60,
    countDownContent: '获取验证码',
    canGetCaptcha: true,

    questionIsFetching: false,
    pageIndex: null,
    totalItemCount: null,
    questionList: [],
    pageState: null,
    isReleaseLoading: false,

    groupByQuestionPropertyList:[],
    getReadBookList:[],
    gradeLevelsList:[],
    queryQuestuonList:{}
}

//删除
function startDeleteQuestuon() {
    return {
        type: START_DELETE_QUESTION
    }
}

function finishDeleteQuestuon() {
    return {
        type: FINISH_DELETE_QUESTION
    }
}

function failDeleteQuestuon() {
    return {
        type: FAIL_DELETE_QUESTION
    }
}

export function deleteQuestuon(params,cb) {
    return dispatch => {
        dispatch(startDeleteQuestuon())
        questionManageService.deleteQuestuon(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishDeleteQuestuon())
                    Modal.success({
                        title: '操作成功！',
                        onOk() {
                            dispatch(push('/index/questionManage/privateQuestionBank?create=false'))
                            cb()
                        }
                    })
                } else {
                    dispatch(failDeleteQuestuon())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

//根据id获取题目信息
function startQueryQuestuon() {
    return {
        type: START_QUERY_QUESTION
    }
}

function finishQueryQuestuon(res) {
    return {
        type: FINISH_QUERY_QUESTION,
        queryQuestuonList: res.questionBank,
    }
}

export function queryQuestuon(params, isCache) {
    return dispatch => {
        dispatch(startQueryQuestuon())
        questionManageService.queryQuestuon(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                console.log(res,'resresresres')
                if (res.success) {
                    let { questionBank } = res.data
                    dispatch(finishQueryQuestuon({questionBank}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

//获取老师所属读本类型
function startGetReadBook() {
    return {
        type: START_GET_READ_BOOK
    }
}

function finishGetReadBook(res) {
    return {
        type: FINISH_GET_READ_BOOK,
        getReadBookList: res.readBookTypes,
        gradeLevelsList: res.gradeLevels,
    }
}

export function getReadBook(params, isCache) {
    return dispatch => {
        dispatch(startGetReadBook())
        questionManageService.getReadBook(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                console.log(res,'resresresres')
                if (res.success) {
                    let { readBookTypes, gradeLevels } = res.data
                    dispatch(finishGetReadBook({readBookTypes, gradeLevels}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

// 查询列表题目属性聚合
function startGroupByQuestionProperty() {
    return {
        type: START_GROUP_BY_QUESTION_PROPERTY
    }
}

function finishGroupByQuestionProperty(res) {
    return {
        type: FINISH_GROUP_BY_QUESTION_PROPERTY,
        groupByQuestionPropertyList: res.questionPros
    }
}

export function groupByQuestionProperty(params, isCache) {
    return dispatch => {
        dispatch(startGroupByQuestionProperty())
        questionManageService.groupByQuestionProperty(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                console.log(res,'resresresres')
                if (res.success) {
                    let { questionPros } = res.data
                    dispatch(finishGroupByQuestionProperty({questionPros}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}


// 题目列表
function startGettingQuestionList() {
    return {
        type: START_LOADING_QUESTION_LIST
    }
}

function finishGettingQuestionList(res, isCache) {
    return {
        type: FINISH_LOADING_QUESTION_LIST,
        pageIndex: res.current,
        totalItemCount: res.total,
        questionList: res.questionBanks,
        pageState: isCache ? res.params : null
    }
}

export function getQuestionList(params, isCache) {
    return dispatch => {
        dispatch(startGettingQuestionList())
        questionManageService.pageQuestuon(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                console.log(res,'resresresres')
                if (res.success) {
                    let { current, total, questionBanks } = res.data
                    dispatch(finishGettingQuestionList({current, total, questionBanks, params}, isCache))
                    if (params.searchWrapper.visibleRange==="PRIVATE_PERSON") {
                        dispatch(push('/index/questionManage/privateQuestionBank?create=false'))
                    }
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

//新增题目
function startCreateQuestion() {
    return {
        type: START_CREATE_QUESTION
    }
}

function finishCreateQuestion() {
    return {
        type: FINISH_CREATE_QUESTION
    }
}

function failCreateQuestion() {
    return {
        type: FAIL_CREATE_QUESTION
    }
}

export function createQuestion(params, isReview, type, pageFrom) {
    return dispatch => {
        dispatch(startCreateQuestion())
        questionManageService[type](params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCreateQuestion())
                    // if (isReview) {
                    //     //保存并提交
                    //     qustionService.changeStatus({wordQuestionStatus: 'REVIEW', wordQuestionId: res.data.wordQuestionId})
                    // }
                    if (type === 'createQuestion') {
                        if (pageFrom === 'editPaper') {
                            Modal.success({
                                title: '新增题目成功！',
                                onOk() {
                                    dispatch(goBack())
                                }
                            })
                        } else {
                            Modal.success({
                                title: '新增题目成功！',
                                onOk() {
                                    dispatch(push('/index/questionManage/privateQuestionBank?create=true'))
                                }
                            })
                        }
                    } else {
                        console.log(pageFrom, 'pageFrom')
                        if (pageFrom === 'editPaper') {
                            const p = getPrivateBtnQuestions();
                            const s = getShareBtnQuestions();
                            const {id, firstQuestion, options} = params.questionBankDTO
                            const pIndex = p.findIndex(item => item.questionBankId === id)
                            const sIndex = s.findIndex(item => item.questionBankId === id)
                            if (pIndex >= 0) {
                                p[pIndex].firstQuestion = firstQuestion
                                p[pIndex].optios = options
                                localStorage.setItem(VISIBLE_RANGE[0].btn + '_selectedQuestions', JSON.stringify(p))
                            } else if (sIndex >= 0) {
                                s[sIndex].firstQuestion = firstQuestion
                                s[sIndex].optios = options
                                localStorage.setItem(VISIBLE_RANGE[1].btn + '_selectedQuestions', JSON.stringify(s))
                            }
                            Modal.success({
                                title: '修改题目成功！',
                                onOk() {
                                    dispatch(goBack())
                                }
                            })
                        }
                        else {
                            Modal.success({
                                title: '修改题目成功！',
                                onOk() {
                                    dispatch(push('/index/questionManage/privateQuestionBank?create=false'))
                                }
                            })
                        }
                    }

                } else {
                    dispatch(failCreateQuestion())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export default function questionManageReducer(state = initState, action) {
    switch (action.type) {
        case START_DELETE_QUESTION:
            return {
                ...state
            }
        case FINISH_DELETE_QUESTION:
            return {
                ...state
            }
        case FAIL_DELETE_QUESTION:
            return {
                ...state
            }
        case START_QUERY_QUESTION:
            return {
                ...state
            }
        case FINISH_QUERY_QUESTION:
            return {
                ...state,
                queryQuestuonList: action.queryQuestuonList
            }
        case START_GET_READ_BOOK:
            return {
                ...state
            }
        case FINISH_GET_READ_BOOK:
            return {
                ...state,
                getReadBookList: action.getReadBookList,
                gradeLevelsList: action.gradeLevelsList,
            }
        case START_GROUP_BY_QUESTION_PROPERTY:
            return {
                ...state
            }
        case FINISH_GROUP_BY_QUESTION_PROPERTY:
            return {
                ...state,
                groupByQuestionPropertyList: action.groupByQuestionPropertyList
            }
        case START_LOADING_QUESTION_LIST:
            return {
                ...state,
                questionIsFetching: true
            }
        case FINISH_LOADING_QUESTION_LIST:
            return {
                ...state,
                questionIsFetching: false,
                pageIndex: action.pageIndex,
                totalItemCount: action.totalItemCount,
                questionList: action.questionList,
                pageState: action.pageState
            }
        case START_CREATE_QUESTION:
            return {
                ...state,
                isLoading: true
            }
        case FINISH_CREATE_QUESTION:
            return {
                ...state,
                isLoading: false
            }
        case FAIL_CREATE_QUESTION:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}
