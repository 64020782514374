/*eslint-disable*/
import axios from '../service'
const url = 'xbizapi/233/question_bank'

let questionManageService = {}

const apis = [
    {
        // 创建题目
        url: url,
        name: 'createQuestion',
        operationType: 'CREATE_QUESTION',
    },
    {
        // 获取老师所属读本类型
        url: url,
        name: 'getReadBook',
        operationType: 'GET_READ_BOOK',
    },
    {
        // 修改题目
        url: url,
        name: 'updateQuestuon',
        operationType: 'UPDATE_QUESTION',
    },
    {
        // 根据id获取题目信息
        url: url,
        name: 'queryQuestuon',
        operationType: 'QUERY_QUESTION',
    },
    {
        // 列表显示
        url: url,
        name: 'pageQuestuon',
        operationType: 'PAGE_QUESTION',
    },
    {
        // 删除题目
        url: url,
        name: 'deleteQuestuon',
        operationType: 'DELETE_QUESTION',
    },
    {
        // 查询列表题目属性聚合
        url: url,
        name: 'groupByQuestionProperty',
        operationType: 'GROUP_BY_QUESTION_PROPERTY',
    }
]

apis.map((api) => {
    questionManageService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default questionManageService