/*eslint-disable*/
import axios from '../service'
const url = '/xbizapi/233/web_task_management'
const exerciseUrl = '/xbizapi/233/exercises_teacherTask'


let taskManageService = {}

const apis = [
    {
        name: 'getTaskList',
        operationType: 'LIST_TASK',
        url
    },
    {
        name: 'createTask',
        operationType: 'CREATE_TEACHER_TASK',
        url: exerciseUrl
    },
    {
        name: 'getTaskDetail',
        operationType: 'QUERY_TEACHER_TASK_INFO',
        url: exerciseUrl
    },
]

apis.map((api) => {
    taskManageService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default taskManageService