import { handleResultCode } from '../../service/service'
import loginService from '../../service/services/login'
import {push} from 'react-router-redux'
import {Modal} from "antd";
const START_LOGIN = 'START_LOGIN'
const FINISH_LOGIN = 'FINISH_LOGIN'
const START_REGISTER = 'START_REGISTER'
const FINISH_REGISTER = 'FINISH_REGISTER'
const START_RESET_PASSWORD = 'START_RESET_PASSWORD'
const FINISH_RESET_PASSWORD = 'FINISH_RESET_PASSWORD'
const START_SWITCH_ROLE = 'START_SWITCH_ROLE'
const FINISH_SWITCH_ROLE  = 'FINISH_SWITCH_ROLE'
const START_GET_PROFILE = 'START_GET_PROFILE'
const FINISH_GET_PROFILE = 'FINISH_GET_PROFILE'
// const START_GET_ROLE_LIST = 'START_GET_ROLE_LIST'
const FINISH_GET_ROLE_LIST = 'FINISH_GET_ROLE_LIST'
const COUNT_DOWN = 'COUNT_DOWN'
const REMOVE_LOGIN_ERROR_MESSAGE = 'REMOVE_LOGIN_ERROR_MESSAGE'
let clock = 0

const initState = {
    userProfileIsFetching: false,
    isFetching: false,
    isGegister: false,
    isSwitchRole: false,
    isGettingProfile: false,
    isResetPassword: false,
    personalInfo: null,
    userRoleList: [],
    captchaCountDown: 60,
    countDownContent: '获取验证码',
    canGetCaptcha: true,
    loginErrorMessage: ''
}

function startLogin() {
    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
    return {
        type: START_LOGIN
    }
}

function finishLogin(userRoleList, cb, errorMessage) {
    if(cb) {
        cb()
    }
    clearInterval(clock)
    return {
        type: FINISH_LOGIN,
        userRoleList,
        errorMessage
    }
}

export function login(params, cb) {
    return (dispatch) => {
        dispatch(startLogin())
        loginService.login(params)
            .then((response) => {
                if (response.status && response.data) {
                    switch (response.data.resultCode) {
                        case 0:
                            return {success: true, data: response.data}
                        case 1:
                            let errorMessage = response.data.displayMessage || response.data.resultMessage
                            if (errorMessage) {
                                dispatch(finishLogin([], null, errorMessage))
                            } else {
                                dispatch(finishLogin([]))
                            }
                            return { success: false, data: response.data }
                        default:
                            Modal.error({
                                title: response.data.resultMessage,
                                zIndex: 10001,
                                onOk() {
                                    if (cb) {
                                        cb()
                                    }
                                }
                            })
                            return { success: false, data: response.data }
                    }
                }
            })
            .then((res) => {
                if (res.success) {
                    window.localStorage.setItem(process.env.REACT_APP_LOCAL_AUTH, res.data.token)
                    let userInfoDTOList = res.data.userInfoDTOList
                    if(userInfoDTOList.length === 1) {
                        dispatch(finishLogin(res.data.userInfoDTOList))
                        dispatch(switchRole({userInfo: userInfoDTOList[0]}, () => {
                            dispatch(push('/index'))
                        }))
                    } else {
                        dispatch(finishLogin(res.data.userInfoDTOList, cb))
                    }
                }
            })
            .catch((e) => {
                console.log(e)
                return
            })
    }
}

function startRegister() {
    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
    return {
        type: START_REGISTER
    }
}

function finishRegister() {
    return {
        type: FINISH_REGISTER,
    }
}

export function register(params, cb) {
    return (dispatch) => {
        dispatch(startRegister())
        loginService.register(params)
            .then((response) => {
                return handleResultCode(response, () => {
                    dispatch(finishRegister())
                })
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishRegister())
                    if(cb) {
                        cb()
                    }
                }
            })
            .catch((e) => {
                console.log(e)
                return
            })
    }
}

function startResetPwd() {
    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
    return {
        type: START_RESET_PASSWORD
    }
}

function finishResetPwd() {
    return {
        type: FINISH_RESET_PASSWORD,
    }
}

export function resetPassword(params, cb) {
    return (dispatch) => {
        dispatch(startResetPwd())
        loginService.resetPassword(params)
            .then((response) => {
                return handleResultCode(response, () => {
                    dispatch(finishResetPwd())
                })
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishResetPwd())
                    if(cb) {
                        cb()
                    }
                }
            })
            .catch((e) => {
                console.log(e)
                return
            })
    }
}

function countDown(captchaCountDown) {
    return {
        type: 'COUNT_DOWN',
        canGetCaptcha: false,
        captchaCountDown: captchaCountDown - 1,
        countDownContent: (captchaCountDown - 1) + 's后重新获取'
    }
}

function handleCountDown() {
    return (dispatch, getState) => {
        let {captchaCountDown} = getState().loginReducer
        dispatch(countDown(captchaCountDown))
    }

}

function clearCountDown() {
    return {
        type: 'COUNT_DOWN',
        countDownContent: '获取验证码',
        captchaCountDown: 60,
        canGetCaptcha: true
    }
}

export function getCaptcha(phoneNumber, bizName) {
    return (dispatch, getState) => {
        let {captchaCountDown} = getState().loginReducer
        dispatch(countDown(captchaCountDown))
        clock = setInterval(() => {
            if (getState().loginReducer.captchaCountDown <= 0) {
                clearInterval(clock)
                dispatch(clearCountDown())
            } else {
                dispatch(handleCountDown())
            }
        },1000)
        loginService.getSms({
            phoneNumber,
            bizName
        })
            .then((response) => {
                return handleResultCode(response, () => {
                    clearInterval(clock)
                    dispatch(clearCountDown())
                })
            })
            .then((res) => {
                //
            })
            .catch((e) => {
                // 提示失败，重置countDown
                clearInterval(clock)
                dispatch(clearCountDown())
                console.log(e)
                return
            })
    }
}

function startGettingUserProfile() {
    return {
        type: START_GET_PROFILE
    }
}

function finishGettingUserProfile(personalInfo) {
    return {
        type: FINISH_GET_PROFILE,
        personalInfo
    }
}

export function getUserProfile() {
    if (window.localStorage.getItem(process.env.REACT_APP_LOCAL_AUTH)) {
        return (dispatch) => {
            dispatch(startGettingUserProfile())
            loginService.getUserProfile()
                .then((response)=>{
                    return handleResultCode(response)
                })
                .then((res) => {
                    if(res.success) {
                        dispatch(finishGettingUserProfile(res.data.personalInfo))
                    }
                })
        }
    } else {
        return (dispatch) => {
            dispatch(push('/login'))
        }
    }
}

function startGettingUserRoleList() {
    return {
        type: 'START_GET_ROLE_LIST'
    }
}

function finishGettingUserRoleList(userRoleList) {
    return {
        type: 'FINISH_GET_ROLE_LIST',
        userRoleList
    }
}

export function getUserRoleList() {
    return (dispatch) => {
        dispatch(startGettingUserRoleList())
        loginService.getUserRoleList()
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingUserRoleList(res.data.userInfos))
                }
            })
    }
}

function startSwitchRole() {
    return {
        type: START_SWITCH_ROLE
    }
}

function finishSwitchRole(cb) {
    if(cb) {
        cb()
    }
    return {
        type: FINISH_SWITCH_ROLE
    }
}

export function switchRole(params, cb) {
    return (dispatch) => {
        dispatch(startSwitchRole())
        loginService.switchRole(params)
            .then((response) => {
                return handleResultCode(response, () => {
                    dispatch(finishSwitchRole())
                })
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishSwitchRole(cb))
                }
            })
            .catch((e) => {
                console.log(e)
                return
            })
    }
}

export function removeLoginErrorMessage() {
    return {
        type: REMOVE_LOGIN_ERROR_MESSAGE
    }
}

export default function loginReducer(state = initState, action) {
    switch (action.type) {
        case START_LOGIN:
            return {
                ...state,
                isFetching: true,
                loginErrorMessage: ''
            }
        case FINISH_LOGIN:
            return {
                ...state,
                isFetching: false,
                userRoleList: action.userRoleList,
                loginErrorMessage: action.errorMessage
            }
        case START_REGISTER:
            return {
                ...state,
                isGegister: true
            }
        case FINISH_REGISTER:
            return {
                ...state,
                isGegister: false
            }
        case START_SWITCH_ROLE:
            return {
                ...state,
                isSwitchRole: true
            }
        case FINISH_SWITCH_ROLE:
            return {
                ...state,
                isSwitchRole: false
            }
        case START_RESET_PASSWORD:
            return {
                ...state,
                isResetPassword: true
            }
        case FINISH_RESET_PASSWORD:
            return {
                ...state,
                isResetPassword: false
            }
        case START_GET_PROFILE:
            return {
                ...state,
                isGettingProfile: true
            }
        case FINISH_GET_PROFILE:
            return {
                ...state,
                isGettingProfile: false,
                personalInfo: action.personalInfo
            }
        case FINISH_GET_ROLE_LIST:
            return {
                ...state,
                userRoleList: action.userRoleList
            }
        case COUNT_DOWN:
            return {
                ...state,
                canGetCaptcha: action.canGetCaptcha,
                captchaCountDown: action.captchaCountDown,
                countDownContent: action.countDownContent
            }
        case REMOVE_LOGIN_ERROR_MESSAGE:
            return {
                ...state,
                loginErrorMessage: ''
            }
        default:
            return state
    }
}
