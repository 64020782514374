import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loginReducer from './reducers/login'
import privatePaperListReducer from './reducers/privatePaperListReducer'
import sharePaperListReducer from './reducers/sharePaperListReducer'
import questionBankReducer from './reducers/questionBankReducer'
import createPaperReducer from './reducers/createPaperReducer'
import editPaperReducer from './reducers/editPaperReducer'
import taskManageReducer from './reducers/taskManageReducer'
import questionManageReducer from './reducers/questionManage'
export default (history) => combineReducers({
    router: connectRouter(history),
    loginReducer,
    privatePaperListReducer,
    sharePaperListReducer,
    questionBankReducer,
    createPaperReducer,
    editPaperReducer,
    taskManageReducer,
    questionManageReducer
})
