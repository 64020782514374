import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import RouterMap from './router/RooterMap'
import configStore from './redux/configStore'
import {unregister} from './serviceWorker'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import './index.css'
const store = configStore()

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <RouterMap/>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)
unregister()
