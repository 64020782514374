import { handleResultCode } from '../../service/service'
import createPaperService from '../../service/services/createPaperService'

const START_CREATE_PAPER = 'START_CREATE_PAPER'
const FINISH_CREATE_PAPER = 'FINISH_CREATE_PAPER'
const CREATE_PAPER_FAIL = 'CREATE_PAPER_FAIL'


const initState = {
}

function startCreatePaper() {
    return {
        type: START_CREATE_PAPER
    }
}

function createPaperSuccess() {
    return {
        type: FINISH_CREATE_PAPER,
    }
}

function createPaperFail() {
    return {
        type: CREATE_PAPER_FAIL
    }
}

export function createPaper(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startCreatePaper())
        createPaperService.createPaper(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    console.log('createPaper', res)
                    dispatch(createPaperSuccess())
                    successCb()
                }
            })
            .catch((e) => {
                dispatch(createPaperFail(e))
            })
    }
}

export default function createPaperReducer(state = initState, action) {
    switch (action.type) {
        case START_CREATE_PAPER:
            return {
                ...state,
            }
        case FINISH_CREATE_PAPER:
            return {
                ...state,
            }
        case CREATE_PAPER_FAIL:
            return {
                ...state,
            }
        default:
            return state
    }
}
