import { handleResultCode } from '../../service/service'
import privatePaperListService from '../../service/services/privatePaperListService'

const START_GET_PAGE_LIST = 'START_GET_PAGE_LIST'
const FINISH_GET_PAGE_LIST = 'FINISH_GET_PAGE_LIST'
const GET_PAGE_LIST_FAIL = 'GET_PAGE_LIST_FAIL'


const START_DELETE_PAPER = 'START_DELETE_PAPER'
const FINISH_DELETE_PAPER = 'FINISH_DELETE_PAPER'
const DELETE_PAPER_FAIL = 'DELETE_PAPER_FAIL'

const initState = {
    isFetchListing: false,
    pageIndex: 1,
    totalItemCount: 0,
    rows: [],
    pageSize: 0
}

function startGetPageList() {
    return {
        type: START_GET_PAGE_LIST
    }
}

function getPageListSuccess(pageIndex, totalItemCount, rows, pageSize) {
    return {
        type: FINISH_GET_PAGE_LIST,
        pageIndex,
        totalItemCount,
        rows,
        pageSize
    }
}

function getPageListFail() {
    return {
        type: GET_PAGE_LIST_FAIL
    }
}

export function getPageList(params) {
    return (dispatch) => {
        dispatch(startGetPageList())
        privatePaperListService.getPageList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {current, total, paperList, size} = res.data
                    dispatch(getPageListSuccess(current, total, paperList, size))
                }
            })
            .catch((e) => {
                dispatch(getPageListFail(e))
            })
    }
}

// 删除试卷
function startDeletePaper() {
    return {
        type: START_DELETE_PAPER
    }
}

function deletePaperSuccess() {
    return {
        type: FINISH_DELETE_PAPER,
    }
}

function deletePaperFail() {
    return {
        type: DELETE_PAPER_FAIL
    }
}

export function deletePaper(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startDeletePaper())
        privatePaperListService.deletePaper(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(deletePaperSuccess())
                    successCb()
                }
            })
            .catch((e) => {
                dispatch(deletePaperFail(e))
            })
    }
}

export default function privatePaperListReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_PAGE_LIST:
            return {
                ...state,
                isFetchListing: true
            }
        case FINISH_GET_PAGE_LIST:
            return {
                ...state,
                isFetchListing: false,
                rows: action.rows,
                totalItemCount: action.totalItemCount,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize
            }
        case GET_PAGE_LIST_FAIL:
            return {
                ...state,
                isFetchListing: false
            }
        case START_DELETE_PAPER:
            return {
                ...state,
            }
        case FINISH_DELETE_PAPER:
            return {
                ...state,
            }
        case DELETE_PAPER_FAIL:
            return {
                ...state,
            }
        default:
            return state
    }
}
