import { Modal } from 'antd'
import axios from 'axios'
import { message } from "antd";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    method: 'post',
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
})

instance.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem(process.env.REACT_APP_LOCAL_AUTH)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    })

instance.interceptors.response.use(
    (response) => {
        return response
    }, (error) => {
        if (error && error.response && error.response.status === 403) {
            Modal.error({
                title: error.response.data.resultMessage,
                zIndex: 10001,
                onOk() {
                    // window.location.reload()
                }
            })
        }
        return Promise.reject(error)
    })

export function handleResultCode(response, onErrCb) {
    if (response.status && response.data) {
        switch (response.data.resultCode) {
            case 0:
                return { success: true, data: response.data }
            case 1:
            case 110:
            case 300:
            case 403: {
                Modal.error({
                    title: response.data.resultMessage,
                    zIndex: 10001,
                    onOk() {
                        if (onErrCb) {
                            onErrCb()
                        }
                        // window.location.reload()
                    }
                })
                return { success: false, data: response.data }
            }
            case 400: {
                Modal.error({
                    title: response.data.resultMessage,
                    zIndex: 10001,
                    onOk() {
                        window.location.reload()
                    }
                })
                return { success: false, data: response.data }
            }
            case 100: {
                message.error({
                    content: '已经在其他地方登录，请重新登录'
                })
                window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
                window.location.reload()
                break
            }
            case 401: {
                window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
                window.location.reload()
                break
            }
            default:
                return { success: false, data: response.data }
        }
    } else {
        return {
            success: false,
            data: response
        }
    }
}


export function handleDownload(response, onErrCb) {
    if (response.status && response.data) {
        if(response.status === 200) {
            console.log(response.headers)
            let fileName = response.headers["content-disposition"].split("filename=")[1]

            let contentType = response.headers["content-type"]
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: contentType}),
                    fileName)
            } else {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: contentType}))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            }
            return { success: true }
        } else {
            switch (response.data.resultCode) {
                case 401:
                case 100: {
                    window.localStorage.removeItem(process.env.REACT_APP_LOCAL_AUTH)
                    window.location.reload()
                    break
                }
                default:
                    return { success: false, data: response.data }
            }
        }

    } else {
        return {
            success: false,
            data: response
        }
    }
}
export default instance
