/*eslint-disable*/
import axios from '../service'
const url = '/xbizapi/233/question_bank'



let questionBankService = {}

const apis = [
    {
        name: 'getQuestionList',
        operationType: 'PAGE_QUESTION',
        url
    },
    {
        name: 'getQuestionItem',
        operationType: 'QUERY_QUESTION',
        url
    },

]

apis.map((api) => {
    questionBankService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default questionBankService
