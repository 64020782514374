/*eslint-disable*/
import axios from '../service'
const url = 'xbizapi/233/web_user'
const smsUrl = 'xbizapi/233/sms'
const userUrl = 'xbizapi/233/user'

let loginService = {}

const apis = [
    {   url: url,
        name: 'login',
        operationType: 'GEN_TOKEN',
    },
    {   url: smsUrl,
        name: 'getSms',
        operationType: 'WEB_SEND_CODE',
    },
    {   url: url,
        name: 'register',
        operationType: 'REGISTER_ACCOUNT',
    },
    {   url: userUrl,
        name: 'switchRole',
        operationType: 'SWITCH_USER',
    },
    {   url: userUrl,
        name: 'getUserProfile',
        operationType: 'PERSONAL_INFO',
    },
    {   url: url,
        name: 'resetPassword',
        operationType: 'UPDATE_PASSWORD',
    },
    {   url: userUrl,
        name: 'getUserRoleList',
        operationType: 'LIST_USER_INFO'
    },
]

apis.map((api) => {
    loginService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default loginService