/*eslint-disable*/
import axios from '../service'
const url = '/xbizapi/233/paper'



let createPaperService = {}

const apis = [
    {
        name: 'createPaper',
        operationType: 'SAVE_OR_UPDATE_PAPER',
        url
    },
]

apis.map((api) => {
    createPaperService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default createPaperService
