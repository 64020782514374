import { handleResultCode } from '../../service/service'
import editPaperService from '../../service/services/editPaperService'

const START_GET_PAPER_DETAIL = 'START_GET_PAPER_DETAIL'
const FINISH_GET_PAPER_DETAIL = 'FINISH_GET_PAPER_DETAIL'
const GET_PAPER_DETAIL_FAIL = 'GET_PAPER_DETAIL_FAIL'


const initState = {
    paperList: []
}

function startGetPaperDetail() {
    return {
        type: START_GET_PAPER_DETAIL
    }
}

function getPaperDetailSuccess(paperList) {
    return {
        type: FINISH_GET_PAPER_DETAIL,
        paperList
    }
}

function createPaperFail() {
    return {
        type: GET_PAPER_DETAIL_FAIL
    }
}

export function getPaperDetail(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetPaperDetail())
        editPaperService.getPaperDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    console.log('getPaperDetail', res)
                    const paperList = res.data.paperList || []
                    dispatch(getPaperDetailSuccess(paperList))
                    successCb()
                }
            })
            .catch((e) => {
                dispatch(createPaperFail(e))
            })
    }
}

export default function createPaperReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_PAPER_DETAIL:
            return {
                ...state,
            }
        case FINISH_GET_PAPER_DETAIL:
            return {
                ...state,
                paperList: action.paperList
            }
        case GET_PAPER_DETAIL_FAIL:
            return {
                ...state,
            }
        default:
            return state
    }
}
