import {VISIBLE_RANGE} from "../../util";

const removePaperStorage = () => {
    localStorage.removeItem('paperInfo')
    localStorage.removeItem(VISIBLE_RANGE[0].btn + '_selectedQuestions')
    localStorage.removeItem(VISIBLE_RANGE[1].btn + '_selectedQuestions')
}

const setPaperInfoStorage = (paperName, applyGrades, sync) => {
    localStorage.setItem('paperInfo', JSON.stringify({
        paperName,
        applyGrades,
        sync,
    }))
}

const getPrivateBtnQuestions = () => {
    return JSON.parse(localStorage.getItem(VISIBLE_RANGE[0].btn + '_selectedQuestions') || '[]')
}

const getShareBtnQuestions = () => {
    return JSON.parse(localStorage.getItem(VISIBLE_RANGE[1].btn + '_selectedQuestions') || '[]')
}



export {removePaperStorage, setPaperInfoStorage, getPrivateBtnQuestions, getShareBtnQuestions }
