import { handleResultCode } from '../../service/service'
import questionBankService from '../../service/services/questionBankService'

const START_GET_QUESTION_LIST = 'START_GET_QUESTION_LIST'
const FINISH_GET_QUESTION_LIST = 'FINISH_GET_QUESTION_LIST'
const GET_QUESTION_LIST_FAIL = 'GET_QUESTION_LIST_FAIL'

const initState = {
    isFetchListing: false,
    pageIndex: 1,
    totalItemCount: 0,
    rows: [],
    pageSize: 0,
}

function startGetQuestionList() {
    return {
        type: START_GET_QUESTION_LIST
    }
}

function getQuestionListSuccess(pageIndex, totalItemCount, rows, pageSize) {
    return {
        type: FINISH_GET_QUESTION_LIST,
        pageIndex,
        totalItemCount,
        rows,
        pageSize
    }
}

function getQuestionListFail() {
    return {
        type: GET_QUESTION_LIST_FAIL
    }
}

export function getQuestionList(params) {
    return (dispatch) => {
        dispatch(startGetQuestionList())
        questionBankService.getQuestionList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                console.log(res, 'getQuestionList---00001')
                if (res.success) {
                    let {current, total, questionBanks, size} = res.data
                    dispatch(getQuestionListSuccess(current, total, questionBanks, size))
                }
            })
            .catch((e) => {
                dispatch(getQuestionListFail(e))
            })
    }
}

export default function questionBankReducer(state = initState, action) {
    switch (action.type) {
        case START_GET_QUESTION_LIST:
            return {
                ...state,
                isFetchListing: true
            }
        case FINISH_GET_QUESTION_LIST:
            return {
                ...state,
                isFetchListing: false,
                rows: action.rows,
                totalItemCount: action.totalItemCount,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize
            }
        case GET_QUESTION_LIST_FAIL:
            return {
                ...state,
                isFetchListing: false
            }
        default:
            return state
    }
}
